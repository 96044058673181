import { transformDateTimeToDayjsUtc } from 'utilities/transformDateTime';

const animations = [
  // { id: 'flare', text: 'Flare' },
  // { id: 'snow', text: 'Snow' },
  // { id: 'confetti_effect', text: 'Confetti Effect' },
  { id: 'only_restaurant', text: 'Only Restaurant' },
  // { id: 'only_pickup', text: 'Only Pickup' },
  { id: 'only_delivery', text: 'Only Delivery' },
  // { id: 'schedule_order', text: 'Schedule order' },
];

export const genetateDefaultState = (rewardId: any, reward: any) => {
  const toCamelCase = (str: string) => str?.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

  const lowerList = animations.filter(item => reward?.contentTags.find((tag: string) => tag === item.id));
  const upperList = animations.filter(item => !reward?.contentTags.find((tag: string) => tag === item.id));

  return {
    activeTab: 0,
    isSetupValid: false,
    isSubtypeValid: false,
    isFilterValid: false,
    isLocationValid: false,
    isActive: reward?.isActive || false,
    locationType: reward?.locationType || 'everywhere',
    locations: rewardId ? reward?.restaurants : [],
    lowerList: lowerList,
    upperList: upperList,
    subtype: reward?.subtabs[0] || null,
    rewardId: rewardId,
    productId: reward?.productId, //Product ID from our catalog
    name: reward?.name || '',
    price: reward?.points || 0,
    sku: reward?.sourceId || null,
    image: reward?.image1 || null,
    weight: reward?.weight || '',
    lastWeight: reward.lastWeight || 0,
    description: reward?.description || '',
    terms: reward?.terms || '',
    campaign: reward?.campaignId || null,
    dailyStartTime: transformDateTimeToDayjsUtc(reward?.rewardSchedule?.dailyStartTime) || null,
    dailyEndTime: transformDateTimeToDayjsUtc(reward?.rewardSchedule?.dailyEndTime) || null,
  };
};
