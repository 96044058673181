import { ArrowForward, Close } from '@material-ui/icons';
import { Button, Form, Input } from 'antd';
import Loading from 'components/Basic/Loading';
import ModalDlg from 'components/Basic/ModalDlg';
import toast from 'components/Basic/Toast';
import { useCreateSubtabsMutation, useUpdateSubtabsMutation } from 'core/rewards/RewarsService';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
type SubtypeType = {
  id: number;
  name: string;
};

type ModalType = {
  isOpenModal: boolean;
  tabsInfo: SubtypeType | null;
  onCloseModal: () => void;
  refetch: () => void;
};

const SubtypeEditModal: React.FC<ModalType> = ({ isOpenModal, onCloseModal, tabsInfo, refetch }) => {
  const [tabsName, setTabsName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [createSubtype] = useCreateSubtabsMutation();
  const [updateSubtype] = useUpdateSubtabsMutation();
  const intl = useIntl();

  const handleTabsNameChange = (e: any) => {
    setTabsName(e.target.value.trimStart());
  };

  const handleSaveButtonClick = async () => {
    let messageId: string;
    const isUpdate = tabsInfo && tabsInfo.id;

    try {
      setIsLoading(true);
      if (isUpdate) {
        await updateSubtype({ name: tabsName.trim(), id: tabsInfo.id }).unwrap();
      } else {
        await createSubtype({ name: tabsName.trim() }).unwrap();
      }
      messageId = isUpdate ? 'Subtype is updated successfully!' : 'Subtype is created successfully!';

      toast.success({ title: intl.formatMessage({ id: messageId }) });
    } catch (error) {
      messageId = isUpdate ? 'Updating subtype is failure!' : 'Creating subtype is failure!';

      toast.error({ title: intl.formatMessage({ id: messageId }) });
    } finally {
      setIsLoading(false);
      refetch();
    }
  };

  useEffect(() => {
    if (tabsInfo) {
      setTabsName(tabsInfo.name || '');
    } else {
      setTabsName('');
    }
  }, [isOpenModal]);

  return (
    <ModalDlg className="edit_subtype_modal" visible={isOpenModal} width={430} handleCancel={onCloseModal} isCenter>
      <>
        <div className="modal_header">
          <div className="name_wrapper">
            <h3>
              <FormattedMessage id={tabsInfo && tabsInfo.id ? 'Edit subtype' : 'New subtype'} />
            </h3>
            <div className="close_modal" onClick={onCloseModal}>
              <Close />
            </div>
          </div>
        </div>
        <div className="new_tabs_form_wrapper">
          <Form className="basic_info_form">
            <div className="tabs_input">
              <Form.Item>
                <p className="title_deals">
                  <FormattedMessage id="Subtype name" />
                </p>
                <Input
                  placeholder={intl.formatMessage({ id: 'Subtype name' })}
                  value={tabsName}
                  onChange={handleTabsNameChange}
                  maxLength={200}
                />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="side_btns_cat">
                <div className="btn_wrapper">
                  {tabsInfo && tabsInfo.id && tabsInfo.name !== tabsName ? (
                    <p
                      className="cancel_btn_tabs"
                      onClick={() => {
                        setTabsName(tabsInfo.name);
                      }}
                    >
                      <FormattedMessage id="Cancel" />
                    </p>
                  ) : (
                    <></>
                  )}
                  <Button
                    className="save_sar_btn"
                    onClick={() => {
                      handleSaveButtonClick();
                      onCloseModal();
                    }}
                    disabled={!tabsName || (tabsInfo ? tabsInfo.name === tabsName : false)}
                  >
                    <FormattedMessage id={tabsInfo && tabsInfo.id ? 'Save changes' : 'Save'} />
                    <ArrowForward />
                  </Button>
                  <Loading visible={isLoading} />
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </>
    </ModalDlg>
  );
};

export default SubtypeEditModal;
