import React, { useState, useEffect } from 'react';
import { compose } from 'react-recompose';
import { Img } from 'react-image';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Tooltip, Button, Pagination } from 'antd';
import moment from 'moment-timezone';
import { Apps, RemoveRedEye } from '@material-ui/icons';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { getProductInfo, getCurrency } from 'utilities/common';
import CatalogueViewModal from 'components/Dashboard/Catalogue/CatalogueViewModal';
import PromoConfigurationModal from './PromoConfigurationModal';
import placeHolderImg from 'assets/img/placeholder.svg';
import { useLazyGetSinglePromoItemQuery } from 'core/catalog/CatalogService';
import Loading from 'components/Basic/Loading';
import toast from '../../../components/Basic/Toast';
// import downloadImg from 'assets/img/file_download.svg';
// import favoriteImg from 'assets/img/favorite.svg';

function CatalogueTable({ intl, productsData, cataloges, prices, sizes, total, onChangePage }) {
  const [isShowDetailModal, setShowDetailModal] = useState(false);
  const [categoryInfo, setCategoryInfo] = useState(null);
  const [current, setCurrent] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isPromoModal, setPromoModal] = useState(false);
  const [promoItem, setPromoItem] = useState();
  const [getSinglePromoItem, { isLoading }] = useLazyGetSinglePromoItemQuery();

  useEffect(() => {
    onChangePage(0, 10);
  }, []);

  const handleChangePage = (page, size) => {
    setCurrent(page);
    setPageSize(size);
    onChangePage((page - 1) * size, size);
  };

  const handleShowSizeChange = (c, s) => {
    handleChangePage(1, s);
  };

  const getCategoryName = classCode => {
    const catalogesData = cataloges.filter(category => category.id === classCode) || [];
    if (catalogesData.length === 0) return '';
    return catalogesData[0].name;
  };
  const getProduct = data => {
    return getProductInfo(data, prices, sizes);
  };

  const handleConfigPromo = async item => {
    if (item.isExtraPromo) {
      try {
        const res = await getSinglePromoItem({ id: item.id }).unwrap();
        const promoData = {
          id: res.id,
          isExtraPromo: res.isExtraPromo,
          isHidden: res.isHidden,
          startDate: res.extraPromoSchedule.startDate?.slice(0, 10),
          startTime: res.extraPromoSchedule.startTime,
          endDate: res.extraPromoSchedule.endDate?.slice(0, 10),
          endTime: res.extraPromoSchedule.endTime,
          minAmount: `${res.extraPromoConfiguration.minPurchaseAmount / 100}`,
          productAmount: `${res.extraPromoConfiguration.maxQuantityPerOrder}`,
        };
        setPromoItem(promoData);
        setPromoModal(true);
      } catch (error) {
        toast.error({ title: intl.formatMessage({ id: 'Error fetching promo product' }) });
      }
    } else {
      const hiddenData = {
        id: item.id,
        isHidden: item.isHidden,
      };
      setPromoItem(hiddenData);
      setPromoModal(true);
    }
  };

  return (
    <>
      <Loading visible={isLoading} />
      <div className="catalogue_table_layout">
        <div className="table_header">
          <div className="food_photo">
            <Apps />
          </div>
          <div className="sku">
            <FormattedMessage id="SKU" />
          </div>
          <div className="name">
            <FormattedMessage id="Name" />
          </div>
          <div className="category">
            <FormattedMessage id="Categories" />
          </div>
          <div className="schedule">
            <FormattedMessage id="Available Schedule" />
          </div>
          <div className="base_price">
            <FormattedMessage id="Base Price" />
          </div>
          <div className="last_import">
            <FormattedMessage id="Last Import" />
          </div>
          <div className="actions">
            <FormattedMessage id="Actions" />
          </div>
        </div>
        <div className="table_content">
          {productsData &&
            productsData.map((item, index) => (
              <div className={`table_item state_${item.state}`} key={index}>
                <div className="food_photo">
                  <div className="photo_wrapper">
                    <Img
                      src={
                        item.productCode !== undefined
                          ? `${process.env.REACT_APP_S3}/${item.channel === 'APP' ? 'customer' : 'ots'}/${
                              item.productCode > 0 ? item.productCode : getProduct(item).productCode
                            }.jpg`
                          : placeHolderImg
                      }
                      alt="food"
                    />
                  </div>
                </div>
                <div className="sku">
                  <p className="number">{item.productCode}</p>
                  <p className="sku_id">{item.id}</p>
                </div>
                <div className="name">
                  <p dangerouslySetInnerHTML={{ __html: item.name }} />
                  <div style={{ display: 'flex' }}>
                    {item.channel && <div className="channel">{intl.formatMessage({ id: `${item.channel}` })}</div>}
                    {item.isExtraPromo && <div className="promo">{intl.formatMessage({ id: 'Promo' })}</div>}
                    {item.isHidden && <div className="hidden">{intl.formatMessage({ id: 'Hidden' })}</div>}
                  </div>
                </div>
                <div className="category">
                  <p dangerouslySetInnerHTML={{ __html: getCategoryName(item.classCode) }} />
                </div>
                <div className="schedule">
                  <p>{item.startTime && item.endTime ? `${item.startTime} hasta ${item.endTime}` : '-'}</p>
                </div>
                <div className="base_price">
                  {prices && (
                    <p>
                      {getCurrency().symbol}
                      {getProduct(item).price}
                    </p>
                  )}
                </div>
                <div className="last_import">
                  <p className="date">{moment(item.updatedAt).tz('America/Guatemala').format('LL')}</p>
                  <p className="time">{moment(item.updatedAt).tz('America/Guatemala').format('HH:mm')}</p>
                </div>
                <div className="actions">
                  {/* <Tooltip placement="top" title="Re-importar producto">
                  <Button className="file_download_btn">
                    <img src={downloadImg} alt="file download" />
                  </Button>
                </Tooltip>
                <Tooltip placement="top" title="Hacer destacado">
                  <Button className="favorite_btn">
                    <img src={favoriteImg} alt="favorite" />
                  </Button>
                </Tooltip> */}
                  <Tooltip placement="top" title={intl.formatMessage({ id: 'See details' })}>
                    <Button
                      className="detail_btn"
                      onClick={() => {
                        setCategoryInfo(item);
                        setShowDetailModal(true);
                      }}
                    >
                      <RemoveRedEye />
                    </Button>
                  </Tooltip>
                  <Tooltip placement="top" title={intl.formatMessage({ id: 'Promotional configuration' })}>
                    <Button
                      className="detail_btn"
                      onClick={() => {
                        handleConfigPromo(item);
                      }}
                    >
                      <WorkspacePremiumIcon />
                    </Button>
                  </Tooltip>
                </div>
              </div>
            ))}
        </div>
        <div className="pagination_wrapper">
          <Pagination
            showSizeChanger
            defaultCurrent={1}
            defaultPageSize={10}
            current={current}
            pageSize={pageSize}
            pageSizeOptions={['5', '10', '20', '30']}
            total={total}
            onChange={handleChangePage}
            onShowSizeChange={handleShowSizeChange}
          />
          <div className="total">Resultados: {total}</div>
        </div>
        {categoryInfo && (
          <CatalogueViewModal
            isOpenModal={isShowDetailModal}
            categoryInfo={categoryInfo}
            categoryName={getCategoryName(categoryInfo && categoryInfo.classCode) || ''}
            prices={prices}
            sizes={sizes}
            productPrice={getProduct(categoryInfo).price || 0}
            onCloseModal={() => setShowDetailModal(false)}
          />
        )}
        {promoItem && (
          <PromoConfigurationModal
            isShowModal={isPromoModal}
            onClosePromoModal={() => setPromoModal(false)}
            item={promoItem}
          />
        )}
      </div>
    </>
  );
}

CatalogueTable.defaultProps = {
  productsData: [],
  cataloges: [],
  prices: [],
  sizes: [],
  total: 0,
};

export default compose(injectIntl)(CatalogueTable);
