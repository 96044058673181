import { ArrowForward, Close } from '@material-ui/icons';
import { Button, Form, Switch, Tabs } from 'antd';
import Loading from 'components/Basic/Loading';
import ModalDlg from 'components/Basic/ModalDlg';
import toast from 'components/Basic/Toast';
import { useUpdateRewardMutation } from 'core/rewards/RewarsService';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import TabContent from './TabContent';
import { genetateDefaultState } from './tools/generateDefaultState';
import { generatePayload } from './tools/generatePayload';
import { Dayjs } from 'dayjs';
import { isValidDailyRange } from './tools/checkValidDates';

type RewardsViewModalType = {
  rewardInfo: any;
  categories: any;
  isShowModal: boolean;
  current: number;
  pageSize: number;
  onShowModal: (e: boolean) => void;
  draftRequest: () => void;
  offersRequest: (c: number, s: number) => void;
};

type Inner = {
  id: string;
  text: string;
};

export type StateType = {
  activeTab: number;
  isActive: boolean;
  locationType: string;
  locations: any;
  lowerList: Array<Inner>;
  upperList: Array<Inner>;
  subtype: any;
  rewardId: number;
  productId: number;
  name: string;
  price: number;
  sku: number;
  image: string;
  weight: string;
  lastWeight: number;
  description: string;
  terms: string;
  campaign: number;
  isSetupValid: boolean;
  isSubtypeValid: boolean;
  isFilterValid: boolean;
  isLocationValid: boolean;
  dailyEndTime: Dayjs | null;
  dailyStartTime: Dayjs | null;
};

const RewardsViewModal: React.FC<RewardsViewModalType> = ({
  rewardInfo,
  isShowModal,
  categories,
  current,
  pageSize,
  onShowModal,
  offersRequest,
}) => {
  const defaultState = genetateDefaultState(rewardInfo?.id, rewardInfo);
  const [state, setState] = useState<StateType>({
    ...defaultState,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const intl = useIntl();
  const history = useHistory();
  const [updateReward] = useUpdateRewardMutation();
  const onCloseRewardModal = () => {
    onShowModal(false);
    history.replace('/dashboard/deals/rewards');
  };

  const tabCount = 3;

  const tabNames = [
    <>
      {intl.formatMessage({ id: 'Product setup' })}
      {!state.isSetupValid && <span className="red_dot" />}
    </>,
    <>
      {intl.formatMessage({ id: 'Subtype' })}
      {!state.isSubtypeValid && <span className="red_dot" />}
    </>,
    <>
      {intl.formatMessage({ id: 'Filters' })}
      {!state.isFilterValid && <span className="red_dot" />}
    </>,
    <>
      {intl.formatMessage({ id: 'Location' })}
      {!state.isLocationValid && <span className="red_dot" />}
    </>,
  ];

  const handleTabClick = (index: number) => {
    tabNames.forEach((_, id) => id < index && validateFields(id));
    setState(prev => ({ ...prev, activeTab: index }));
  };

  const callbackTabClicked = (key: string) => {
    handleTabClick(+key);
  };

  const onControllerClick = (action: any) => {
    if (state?.activeTab >= 0 && state?.activeTab <= tabCount) {
      if (action === 'next') {
        validateFields();
        const newActiveTab = +state.activeTab + 1;
        setState(prev => ({ ...prev, activeTab: newActiveTab <= tabCount ? newActiveTab : tabCount }));
      } else {
        const newActiveTab = +state.activeTab - 1;
        setState(prev => ({ ...prev, activeTab: newActiveTab >= 0 ? newActiveTab : 0 }));
      }
    }
  };
  const validateFields = (id?: number) => {
    const caseData = id !== undefined && id >= 0 ? id : state.activeTab;

    switch (+caseData) {
      case 0:
        const isInfoValid =
          state.description?.length > 0 && state.terms?.length > 0 && Number(state.weight) > 0 && state.campaign > 0;
        const isDateRangeValid = isValidDailyRange(state.dailyStartTime, state.dailyEndTime);
        const isDate = !!state.dailyEndTime && !!state.dailyStartTime;
        const isSetupValid = isInfoValid && isDateRangeValid === true && isDate;
        setState(prev => ({ ...prev, isSetupValid }));
        return isSetupValid;
      case 1:
        const isSubtypeValid = state.subtype > 0;
        setState(prev => ({ ...prev, isSubtypeValid }));
        return isSubtypeValid;
      case 2:
        const isFilterValid = !!state.lowerList?.length;
        setState(prev => ({ ...prev, isFilterValid }));
        return isFilterValid;
      case 3:
        const isLocationValid =
          state?.locationType === 'everywhere'
            ? !!state?.locationType
            : !!(state?.locationType && state.locations?.length > 0);

        setState(prev => ({ ...prev, isLocationValid }));
        return isLocationValid;
      default:
        return false;
    }
  };

  const saveReward = async () => {
    const isTabsValid = tabNames.map((_, index) => validateFields(index)).includes(false);
    if (isTabsValid) {
      toast.error({ title: intl.formatMessage({ id: 'Updating reward is failure' }) });
    } else {
      setLoading(true);
      try {
        const payload = generatePayload(state);
        await updateReward({ data: payload, id: rewardInfo?.id }).unwrap();
        offersRequest(current, pageSize);
        toast.success({ title: intl.formatMessage({ id: 'Reward is updated successfully' }) });
        onCloseRewardModal();
      } catch (error) {
        toast.error({ title: intl.formatMessage({ id: 'Updating reward is failure' }) });
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    if (isShowModal) {
      tabNames.forEach((_, index) => validateFields(index));
    }
  }, [isShowModal]);

  return (
    <>
      <ModalDlg
        className="rewards_view_modal"
        visible={isShowModal}
        width={430}
        handleCancel={onCloseRewardModal}
        isCenter
      >
        <>
          <div className="modal_header">
            <div className="name_wrapper">
              <h3>
                <FormattedMessage id={rewardInfo?.id ? 'Edit Reward' : ''} />
              </h3>
              {rewardInfo?.id && (
                <div className="name_wrapper_id">
                  <FormattedMessage id="OfferId" values={{ id: rewardInfo?.id }} />
                </div>
              )}
              <div className="close_modal" onClick={onCloseRewardModal}>
                <Close />
              </div>
            </div>
          </div>
          <div className="new_reward_form_wrapper">
            <Tabs
              defaultActiveKey="0"
              tabPosition={'top'}
              onTabClick={callbackTabClicked}
              className="offers_tab"
              popupClassName="tabs_more_menu"
              activeKey={`${state.activeTab}`}
              items={tabNames.map((item, i) => {
                const id = String(i);
                return {
                  label: item,
                  key: id,
                };
              })}
            />
            <Form className="basic_info_form">
              <div className="tab_content">
                <TabContent state={state} setState={setState} index={state.activeTab} />
              </div>
              <Form.Item>
                <>
                  <div className="btn_arrows">
                    <Button
                      className="arrow_left"
                      onClick={() => onControllerClick('prev')}
                      style={state.activeTab === 0 ? { visibility: 'hidden' } : {}}
                    >
                      <ArrowForward style={{ transform: 'rotate(180deg)' }} />
                      <FormattedMessage id={'Prev'} />
                    </Button>
                    <Button
                      className="arrow_right"
                      onClick={() => onControllerClick('next')}
                      style={+state.activeTab === tabCount ? { visibility: 'hidden' } : {}}
                    >
                      <FormattedMessage id={'Next'} />
                      <ArrowForward />
                    </Button>
                  </div>
                  <div className="side_btns_cat">
                    <div className="aditional_detail">
                      <p id="campaign_form_switcher_label">
                        <FormattedMessage id="Active" />
                      </p>
                      <Switch
                        checked={state.isActive}
                        onChange={checked => setState(prev => ({ ...prev, isActive: checked }))}
                        id="campaign_form_switcher"
                      />
                    </div>
                    <div className="btn_wrapper_offer">
                      {/* <Button className="save_sar_btn" disabled={disabledPreveiw()} onClick={onShowPreviewModal}>
                        <FormattedMessage id={'Preview'} />
                        <RemoveRedEyeOutlined />
                      </Button> */}
                      <Button className="save_sar_btn" disabled={loading} onClick={saveReward}>
                        <FormattedMessage id={!rewardInfo?.id ? 'Save' : 'Save changes'} />
                        <ArrowForward />
                      </Button>
                    </div>
                  </div>
                </>
              </Form.Item>
            </Form>
          </div>
        </>
      </ModalDlg>
      {loading && <Loading visible={loading} />}
    </>
  );
};

export default RewardsViewModal;
