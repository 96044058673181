import dayjs, { Dayjs } from 'dayjs';
import moment from 'moment-timezone';

export const isValidDailyRange = (dailyStartTime: Dayjs | null, dailyEndTime: Dayjs | null) => {
  const formatedDailyStartTime = dayjs(dailyStartTime).format('HH:mm');
  const formatedDailyEndTime = dayjs(dailyEndTime).format('HH:mm');
  const day = dayjs().format('YYYY-MM-DD');

  const dailyStart = moment(`${day} ${formatedDailyStartTime}`);
  const dailyEnd = moment(`${day} ${formatedDailyEndTime}`);

  if (dailyStart.isSameOrAfter(dailyEnd)) {
    return 'daily1';
  }
  return true;
};
