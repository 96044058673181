import React from 'react';
import Location from './Location';
import Filters from './Filter';
import Subtype from './Subtype';
import { StateType } from './RewardViewModal';
import Setup from './Setup';

type TabContentType = {
  state: StateType;
  setState: (e: any) => void;
  index: number;
};

const animations = [
  { id: 'flare', text: 'Flare' },
  { id: 'snow', text: 'Snow' },
  { id: 'confetti_effect', text: 'Confetti Effect' },
  { id: 'only_restaurant', text: 'Only Restaurant' },
];

const TabContent: React.FC<TabContentType> = ({ state, index, setState }) => {
  const activeTab = Number(index);
  return (
    <>
      {activeTab === 3 && (
        <Location
          isLocation={state.locationType}
          setIsLocation={value => setState((prev: StateType) => ({ ...prev, locationType: value }))}
          setSelectedRestaurant={value => setState((prev: StateType) => ({ ...prev, locations: value }))}
          selectedRestaurant={state.locations}
          isValid={state.isLocationValid}
        />
      )}
      {activeTab === 2 && (
        <Filters
          lowerList={state.lowerList}
          setLowerList={value => setState((prev: StateType) => ({ ...prev, lowerList: value }))}
          upperList={state.upperList}
          setUpperList={value => setState((prev: StateType) => ({ ...prev, upperList: value }))}
          isValid={state.isFilterValid}
        />
      )}
      {activeTab === 1 && (
        <Subtype
          isValid={state.isSubtypeValid}
          selectedSubtype={state.subtype}
          setSelectedSubtype={value => setState((prev: StateType) => ({ ...prev, subtype: value }))}
        />
      )}
      {activeTab === 0 && (
        <Setup
          productId={state.productId}
          name={state.name}
          price={state.price}
          sku={state.sku}
          image={state.image}
          weight={state.weight}
          lastWeight={state.lastWeight}
          description={state.description}
          terms={state.terms}
          campaign={state.campaign}
          isValid={state.isSetupValid}
          dailyStartTime={state.dailyStartTime}
          dailyEndTime={state.dailyEndTime}
          setWeight={value => setState((prev: StateType) => ({ ...prev, weight: value }))}
          setDescription={value => setState((prev: StateType) => ({ ...prev, description: value }))}
          setTerms={value => setState((prev: StateType) => ({ ...prev, terms: value }))}
          setCampaign={value => setState((prev: StateType) => ({ ...prev, campaign: value }))}
          onDailyStartTime={value => setState((prev: StateType) => ({ ...prev, dailyStartTime: value }))}
          onDailyEndTime={value => setState((prev: StateType) => ({ ...prev, dailyEndTime: value }))}
        />
      )}
    </>
  );
};

export default TabContent;
